import { persistCombineReducers } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage'
import { auth } from '@osrdata/app_core'
import appReducer from './app'
import paramsReducer from './params'
import cerbereReducer from './cerbere'
import manageReducer from './manage'
import missionTableReducer from './missionTable'
import RdReducer from './rd'

const userReducer = auth.reducer

const userWhiteList = [
  'account',
]

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter],
  whitelist: ['user'],
}

export const rootReducer = {
  user: userReducer,
  app: appReducer,
  params: paramsReducer,
  cerbere: cerbereReducer,
  manage: manageReducer,
  missionTable: missionTableReducer,
  rd: RdReducer,

}

const persistedRootReducer = persistCombineReducers(persistConfig, rootReducer)

export default persistedRootReducer
