import { auth } from '@osrdata/app_core'
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined'
import { Apps, MenuBook } from '@mui/icons-material'
import { ADMIN_PERM, useAppDispatch, useAppSelector } from 'utils'
import { useNavigate } from 'react-router-dom'
import { MenuItem } from '@mui/material'
import terms from 'assets/terms'
import ManualPdf from 'assets/r2d2_manuel.pdf'

export enum MenuItemType {
  roleSwap = 'roleSwap',
  manual = 'manual',
  disconnect = 'disconnect',
}

interface ItemProps {
  type: MenuItemType;
  closeMenu: (next?: () => void) => void
}

export function Item({ type, closeMenu }: ItemProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { userRoles } = useAppSelector(state => state.app)
  const { appPermissions } = useAppSelector(state => state.user)

  const canSelectRole = () => {
    const adminRole = appPermissions.includes(ADMIN_PERM) ? 1 : 0
    const roleCount = userRoles.flatMap(r => r.roles).length
    return (roleCount + adminRole) > 1
  }

  const getMenuItem = () => {
    switch (type) {
      case MenuItemType.roleSwap:
        return {
          label: terms.AccountMenu.roleSelection,
          icon: <Apps />,
          onClick: () => { navigate('/') },
          show: canSelectRole(),
        }
      case MenuItemType.disconnect:
        return {
          label: terms.AccountMenu.logout,
          icon: <PowerSettingsNewOutlinedIcon />,
          onClick: () => dispatch(auth.logout()),
          show: true,
        }
      case MenuItemType.manual:
        return {
          label: terms.AccountMenu.manual,
          icon: <MenuBook />,
          onClick: () => {
            const link = document.createElement('a')
            link.href = ManualPdf
            link.target = '_blank'
            link.click()
          },
          show: true,
        }
      default:
        return {
          label: '',
          icon: undefined,
          onClick: () => undefined,
          show: false,
        }
    }
  }
  const { label, icon, onClick, show } = getMenuItem()

  if (!show) return null
  return (
    <MenuItem className="flex-center" onClick={() => closeMenu(onClick)}>
      {icon && (
      <div className="menu-item-icon flex-center">
        {icon}
      </div>
      )}
      <div className="menu-item-title">
        {label}
      </div>
    </MenuItem>
  )
}
