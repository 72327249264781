import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import './TimeFormField.scss'
import { useEffect, useState } from 'react'
import { Checkbox } from 'components'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import moment from 'moment'

interface Props {
  value: moment.Moment | null;
  onChange: (newValue: moment.Moment) => void;
  label: string;
  disabled?: boolean;
  required?: boolean;
}

export default function TimeFormField({
  value,
  onChange,
  label,
  disabled,
  required,
}: Props) {
  const [timeDelta, setTimeDelta] = useState<number>(value ? moment(value).dayOfYear() - moment().dayOfYear() : 0)
  const handleTimeChange = (newValue: moment.Moment) => {
    const valueHours = newValue.hours()
    const valueMinutes = newValue.minutes()

    let newTime = moment().hours(valueHours).minutes(valueMinutes)
    newTime = newTime.add(timeDelta, 'days')

    onChange(newTime)
  }

  const handleAddTimeDelta = () => {
    setTimeDelta(prev => prev + 1)
  }

  const handleSubtractTimeDelta = () => {
    setTimeDelta(prev => prev - 1)
  }

  useEffect(() => {
    if (value) {
      handleTimeChange(value)
    }
  }, [timeDelta])

  return (
    <div className="time-form-field">
      <span>
        {label}
        {required && <span style={{ color: '#DA4238' }}>*</span>}
      </span>
      <span />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker
          ampm={false}
          value={value}
          onChange={handleTimeChange}
          disabled={disabled}
        />
      </LocalizationProvider>
      <div className="time-delta">
        <Checkbox
          checked={!!timeDelta}
          onChange={() => setTimeDelta(prev => (prev ? 0 : 1))}
          label={`J${timeDelta > 0 ? `+${timeDelta}` : timeDelta}`}
        />
        <div className="time-delta-buttons">
          <button type="button" onClick={handleAddTimeDelta}>
            <KeyboardArrowUp />
          </button>
          <button type="button" onClick={handleSubtractTimeDelta}>
            <KeyboardArrowDown />
          </button>
        </div>
      </div>
    </div>
  )
}
