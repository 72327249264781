import { useAppSelector } from 'utils'
import './Tabs.scss'
import { AppRoles } from 'types'
import { PeopleOutlined, History } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { ModalExportHistory, ModalManageTeam, modalSignal } from 'components/modals'
import { AudioToggle } from 'components'

export default function Tabs() {
  const { activeRole } = useAppSelector(state => state.app)
  const { activeTeams, activeAgentsCount, teams, agents } = useAppSelector(state => state.missionTable)

  const handleClickTeamButton = () => {
    if (activeRole.role !== AppRoles.com) return
    modalSignal.value = (
      <ModalManageTeam
        technicenterId={activeRole.technicenterId}
        technicenterTeams={teams}
        technicenterAgents={agents}
        handleClose={() => { modalSignal.value = null }}
      />
    )
  }

  const handleClickExportHistoryButton = () => {
    if (activeRole.role !== AppRoles.com && activeRole.role !== AppRoles.consultant) return
    modalSignal.value = (
      <ModalExportHistory
        technicalCenterId={activeRole.technicenterId}
        handleClose={() => { modalSignal.value = null }}
      />
    )
  }

  return (
    <div className="topbar-tabs flex-center">
      {(activeRole?.role === AppRoles.com || activeRole?.role === AppRoles.consultant) && (
        <button
          type="button"
          className="flex-center"
          onClick={handleClickExportHistoryButton}
        >
          <History />
          <span style={{ padding: 0, marginLeft: '8px' }}>Historique</span>
        </button>
      )}
      {(activeRole?.role === AppRoles.com || activeRole?.role === AppRoles.consultant) && (
        <button
          type="button"
          className="flex-center team-button"
          onClick={handleClickTeamButton}
          disabled={activeRole.role !== AppRoles.com}
        >
          <PeopleOutlined />
          <div className="infos">
            <strong>Equipe(s)</strong>
            {activeTeams.length === 0 || activeAgentsCount === 0 ? (
              <span>Aucune équipe active</span>
            ) : (
              <Tooltip title={`${activeTeams.join(', ')} - ${activeAgentsCount} agent(s)`}>
                <span>{`${activeTeams.join(', ')} - ${activeAgentsCount} agent(s)`}</span>
              </Tooltip>
            )}
          </div>
        </button>
      )}
      {activeRole?.role === AppRoles.RD && (
        <AudioToggle />
      )}
    </div>
  )
}
