import { useNavigate } from 'react-router-dom'
import { routes } from 'services'
import terms from 'assets/terms'
import { ActiveRole, AppRoles } from 'types'
import r2d2Logo from 'assets/logos/r2d2.svg'
import sncfLogo from 'assets/logos/sncf.svg'
import { sideMenuOpenedSignal } from 'components'
import { useAppSelector } from 'utils'
import Burger from './burger/Burger'
import AccountMenu from './menu/MenuAccount'

import './TopBar.scss'
import Tabs from './tabs/Tabs'

const renderContent = (activeRole: ActiveRole) => {
  switch (activeRole?.role) {
    case AppRoles.admin:
      return (<span>{terms.Common.roles[AppRoles.admin]}</span>)
    case AppRoles.manager:
      return (<span>{`${terms.Common.roles[AppRoles.manager]} - ${activeRole.technicenterName}`}</span>)
    default:
      return null
  }
}

type Props = {
  appName: string;
  enableSideMenu: boolean;
  hasAccess: boolean;
}

export default function TopBar({ appName, enableSideMenu, hasAccess }: Props) {
  const navigate = useNavigate()
  const { activeRole } = useAppSelector(state => state.app)
  const menuHidden = !hasAccess || !enableSideMenu
  const toggleMenu = () => {
    sideMenuOpenedSignal.value = !sideMenuOpenedSignal.value
  }

  return (
    <header className={menuHidden ? 'no-menu' : ''}>
      {!menuHidden && <Burger opened={sideMenuOpenedSignal.value} action={toggleMenu} />}
      <div className="logos">
        <div className="flex-center">
          <img alt={appName} src={sncfLogo} />
        </div>
        <button type="button" className="flex-center" onClick={() => navigate(routes.home)}>
          <img alt="R2D2" src={r2d2Logo} />
        </button>
      </div>
      <div className="content">
        {renderContent(activeRole)}
        <Tabs />
      </div>
      <AccountMenu />
    </header>
  )
}
