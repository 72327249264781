import { Add, ArrowDropDownRounded, ArrowRightRounded, Delete } from '@mui/icons-material'
import TooltipButton from 'components/tooltipButton/TooltipButton'
import { useState } from 'react'
import { ManageTeam } from 'types'
import './TeamAccordion.scss'
import { ModalAddUser, ModalConfirm, modalSignal } from 'components/modals'
import terms from 'assets/terms'
import { handleAddMemberToTeam, handleDeleteTeam, handleRemoveMemberFromTeam } from 'services'
import { useCerbereUsers } from 'utils'
import { useDroppable } from '@dnd-kit/core'
import TeamAgent from './TeamAgent'

interface Props {
  id: string;
  technicalCenterId: string;
  team: ManageTeam;
}

const localTerms = terms.Pages.Manage.teams
export default function TeamAccordion({
  id, technicalCenterId, team,
}: Props) {
  const { isOver, setNodeRef } = useDroppable({ id, data: { teamId: team.id } })
  const { getUserName, loadingUsers: usersLoading } = useCerbereUsers()
  const [expanded, setExpanded] = useState(false)

  const handleClickDelete = () => {
    modalSignal.value = (
      <ModalConfirm
        title={localTerms.confirmDelete}
        handleClose={() => { modalSignal.value = null }}
        handleValidate={handleDeleteTeam(technicalCenterId, team.id, () => { modalSignal.value = null })}
      />
    )
  }

  const handleClickAddMember = () => {
    modalSignal.value = (
      <ModalAddUser
        title={localTerms.addMember}
        placeholder={localTerms.memberLabel}
        userLabel={localTerms.memberLabel}
        handleClose={() => { modalSignal.value = null }}
        handleValidate={handleAddMemberToTeam(technicalCenterId, team.id, () => { modalSignal.value = null })}
      />
    )
  }

  const handleClickDeleteMember = (cerbereId: string) => () => {
    modalSignal.value = (
      <ModalConfirm
        title={localTerms.confirmDeleteMember}
        handleClose={() => { modalSignal.value = null }}
        handleValidate={handleRemoveMemberFromTeam(
          technicalCenterId,
          team.id,
          cerbereId,
          () => { modalSignal.value = null },
        )}
      />
    )
  }

  return (
    <div className={`team-accordion ${isOver ? 'highlight' : ''}`} ref={setNodeRef}>
      <div className="header">
        <button
          className="expand"
          type="button"
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (<ArrowDropDownRounded />) : (<ArrowRightRounded />)}
          <span>{team.libelle}</span>
        </button>
        <div className="actions">
          <TooltipButton
            tooltipText={terms.Pages.Manage.teams.delete}
            onClick={handleClickDelete}
            icon={<Delete />}
          />
        </div>
      </div>
      {expanded && !usersLoading && (
        <div className="content">
          {team.agents.map(agent => (
            <TeamAgent
              key={agent}
              id={agent}
              teamId={team.id}
              agentName={getUserName(agent)}
              handleDelete={handleClickDeleteMember(agent)}
            />
          ))}
          <button type="button" className="add-member" onClick={handleClickAddMember}>
            <Add />
            <span>{localTerms.addMember}</span>
          </button>
        </div>
      )}
    </div>
  )
}
