import { Add, Delete } from '@mui/icons-material'
import terms from 'assets/terms'
import { AsyncInput, Button, Loader, AddFieldInput, TooltipButton } from 'components'
import { useEffect, useState } from 'react'
import { getDefects } from 'reducers/params/thunks'
import { useAppDispatch, useAppSelector } from 'utils'
import { handleChangeDefectName, handleDeleteDefect, handlePostNewDefect, handleReactivateDefect } from 'services'
import './DefectsForm.scss'

const localTerms = terms.Pages.Params.defects
export default function DefectsForm() {
  const dispatch = useAppDispatch()
  const { defects, defectsLoading } = useAppSelector(state => state.params)
  const [addingDefect, setAddingDefect] = useState(false)

  useEffect(() => {
    dispatch(getDefects())
  }, [])

  if (defectsLoading) {
    return (
      <Loader message={localTerms.loading} standalone />
    )
  }

  return (
    <div className="defects-form">
      {defects.map(defect => (
        <div className={`item ${defect.active ? '' : 'inactive'}`} key={defect.id}>
          <div className="input">
            <span>{localTerms.label}</span>
            <AsyncInput
              value={defect.libelle}
              handleChangeCallback={handleChangeDefectName(defect.id)}
            />
          </div>
          {defect.active ? (
            <TooltipButton
              icon={<Delete />}
              tooltipText={localTerms.delete}
              onClick={handleDeleteDefect(defect.id)}
            />
          ) : (
            <TooltipButton
              icon={<Add />}
              tooltipText={localTerms.reactivate}
              onClick={handleReactivateDefect(defect.id)}
            />
          )}
        </div>
      ))}
      {addingDefect ? (
        <AddFieldInput
          title={localTerms.label}
          addTooltip={localTerms.add}
          cancelTooltip={localTerms.cancel}
          handleAdd={handlePostNewDefect(() => setAddingDefect(false))}
          handleDelete={() => () => setAddingDefect(false)}
        />
      ) : (
        <Button
          className="add-defect-button"
          onClick={() => setAddingDefect(true)}
          text={localTerms.add}
          startIcon={<Add />}
        />
      )}
    </div>
  )
}
