import { Box, Stack } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button, ButtonStyle,
} from 'components'
import { isMissionNotification, routes } from 'services'
import { AppRoles } from 'types'
import { useAppDispatch, useAppSelector, usePageRoles } from 'utils'
import { getAffectedMissionsRD, getInProgressMissionsRD } from 'reducers/rd/thunks'
import { getDefects, getObservations } from 'reducers/params/thunks'
import { GATEWAY_DOMAIN } from 'config/config'
import { playAudioNotification } from 'components/audioToggle/AudioToggle'

export default function RdPage() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  usePageRoles([AppRoles.RD])
  const { activeRole } = useAppSelector(state => state.app)
  const { missionsRD, inProgressMissionsRD } = useAppSelector(state => state.rd)
  const { id } = useAppSelector(state => state.user.account)

  useEffect(() => {
    if (activeRole && activeRole.role !== AppRoles.admin) {
      dispatch(getAffectedMissionsRD(activeRole.technicenterId))
      dispatch(getInProgressMissionsRD(activeRole.technicenterId))
      dispatch(getDefects())
      dispatch(getObservations())
    }
  }, [activeRole])

  const ws = useRef<WebSocket>()

  useEffect(() => {
    if (activeRole?.role === AppRoles.RD) {
      ws.current = new WebSocket(
        // eslint-disable-next-line max-len
        `wss://${GATEWAY_DOMAIN.url}/r2d2/technicentres/${activeRole.technicenterId}/connect/?_auth_token=${`${localStorage.getItem(
          'access_token',
        )}`}&user=${id}`,
      )
      ws.current.onmessage = (event: { data: string }) => {
        const newMessage = JSON.parse(event.data)
        if (isMissionNotification(newMessage)) {
          dispatch(getAffectedMissionsRD(activeRole.technicenterId))
          dispatch(getInProgressMissionsRD(activeRole.technicenterId))
          if (newMessage.type === 'AFFECTATION') {
            playAudioNotification()
          }
        }
      }
    }

    return () => {
      if (ws.current) {
        ws.current.close()
      }
    }
  }, [activeRole?.role])

  return (
    <Box sx={{ background: '#F5F9FF', margin: '12px', padding: '12px', height: 'calc(100% - 24px)' }}>
      <Stack spacing={4}>
        <Box width="100%" display="flex" justifyContent="center" fontSize={20}>
          Menu
        </Box>
        <Button
          fullWidth
          text={`${missionsRD.filter(mission => mission.statut === 'AFFECTEE').length} mission(s) affectée(s)`}
          onClick={() => {
            navigate(`${routes.rd}${routes.rdMissionsList}/affectee`)
          }}
          style={ButtonStyle.primary}
          disabled={missionsRD.filter(mission => mission.statut === 'AFFECTEE').length === 0}
        />
        <Button
          fullWidth
          text={`${inProgressMissionsRD.length} mission(s) en cours`}
          onClick={() => {
            navigate(`${routes.rd}${routes.rdMissionsList}/inprogress`)
          }}
          style={ButtonStyle.primary}
          disabled={inProgressMissionsRD.length === 0}
        />
        <Button
          fullWidth
          text="Missions supprimées / refusées"
          onClick={() => {
            navigate(`${routes.rd}${routes.rdMissionsList}/refusee`)
          }}
          style={ButtonStyle.primary}
          disabled={missionsRD.filter(mission => mission.statut === 'REFUSEE'
            || mission.statut === 'SUPPRIMEE').length === 0}

        />
        <Button
          fullWidth
          text="Missions validées"
          onClick={() => {
            navigate(`${routes.rd}${routes.rdMissionsList}/validee`)
          }}
          style={ButtonStyle.primary}
          disabled={missionsRD.filter(mission => mission.statut === 'VALIDEE').length === 0}
        />
      </Stack>
    </Box>
  )
}
