import { useEffect, useRef, useState } from 'react'
import AddFormWrapper from 'components/addForms/AddFormWrapper'
import './AddTwoFieldInputs.scss'

interface Props {
  fieldsTitle: [string, string];
  addTooltip: string;
  cancelTooltip: string;
  fieldsType?: [string, string];
  handleAdd: (firstInput: string, secondValue: string) => () => void;
  handleDelete: () => () => void;
}

export default function AddTwoFieldInputs({
  fieldsTitle, addTooltip, cancelTooltip, handleAdd, handleDelete, fieldsType = ['text', 'text'],
}: Props) {
  const [firstInputValue, setFirstInputValue] = useState('')
  const [secondInputValue, setSecondInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChangeFirst = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstInputValue(e.target.value)
  }

  const handleChangeSecond = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSecondInputValue(e.target.value)
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <AddFormWrapper
      className="add-two-field-input"
      addTooltip={addTooltip}
      cancelTooltip={cancelTooltip}
      handleAdd={handleAdd(firstInputValue, secondInputValue)}
      handleDelete={handleDelete()}
    >
      <div className="two-input">
        <div className="input">
          <span>{fieldsTitle[0]}</span>
          <input
            ref={inputRef}
            type={fieldsType[0]}
            value={firstInputValue}
            onChange={handleChangeFirst}
            maxLength={100}
          />
        </div>
        <div className="input">
          <span>{fieldsTitle[1]}</span>
          <input
            ref={inputRef}
            type={fieldsType[1]}
            value={secondInputValue}
            onChange={handleChangeSecond}
            maxLength={100}
          />
        </div>
      </div>
    </AddFormWrapper>
  )
}
