import { Check, DeleteOutlined, EditOutlined, Inventory2Outlined } from '@mui/icons-material'
import { ModalConfirm, ModalPatchMissionTC, modalSignal } from 'components'
import { handleDeleteMissionTC, handlePatchMissionTC } from 'services'
import { MISSION_ARCHIVE_STATUS, MISSION_DELETE_STATUS, MISSION_EDIT_STATUS, MissionStatus, MissionTC } from 'types'
import { useAppDispatch } from 'utils'
import { getMissionsTC } from 'reducers/missionTable/thunks'

import './MissionActions.scss'
import moment from 'moment'

interface Props {
  mission: MissionTC
  technicalCenterId: string
  disabled: boolean
  ordering: string
}

export default function MissionActions({ mission, technicalCenterId, disabled, ordering }: Props) {
  const dispatch = useAppDispatch()
  if (disabled) {
    return (<div className="mission-actions">-</div>)
  }
  const handleClickDelete = () => {
    modalSignal.value = (
      <ModalConfirm
        title="Êtes-vous sûr de vouloir supprimer cette mission ?"
        handleClose={() => { modalSignal.value = null }}
        handleValidate={() => handleDeleteMissionTC(technicalCenterId, mission.id, () => {
          modalSignal.value = null
          dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering }))
        })}
      />
    )
  }

  const handleClickEdit = () => {
    modalSignal.value = (
      <ModalPatchMissionTC
        handleClose={() => { modalSignal.value = null }}
        technicalCenterId={technicalCenterId}
        mission={mission}
        ordering={ordering}
      />
    )
  }

  const handleClickArchive = () => {
    modalSignal.value = (
      <ModalConfirm
        title="Êtes-vous sûr de vouloir archiver cette mission ?"
        handleClose={() => { modalSignal.value = null }}
        handleValidate={handlePatchMissionTC(
          technicalCenterId,
          mission.id,
          () => {
            modalSignal.value = null
            dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering }))
          },
          { archivee: true },
        )}
      />
    )
  }

  const handleClickValidate = () => {
    modalSignal.value = (
      <ModalConfirm
        title="Êtes-vous sûr de vouloir valider cette mission ?"
        handleClose={() => { modalSignal.value = null }}
        handleValidate={handlePatchMissionTC(
          technicalCenterId,
          mission.id,
          () => {
            modalSignal.value = null
            dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering }))
          },
          {
            statut: MissionStatus.validated,
            validation: moment().format(),
          },
        )}
      />
    )
  }

  return (
    <div className="mission-actions">
      {MISSION_EDIT_STATUS.includes(mission.statut) && (
        <button type="button" onClick={handleClickEdit}>
          <EditOutlined />
        </button>
      )}
      {MISSION_DELETE_STATUS.includes(mission.statut) && (
        <button type="button" onClick={handleClickDelete}>
          <DeleteOutlined />
        </button>
      )}
      {MISSION_ARCHIVE_STATUS.includes(mission.statut) && (
        <button type="button" onClick={handleClickArchive}>
          <Inventory2Outlined />
        </button>
      )}
      {mission.statut === MissionStatus.inProgress && mission.fin_npd && (
        <button type="button" onClick={handleClickValidate}>
          <Check />
        </button>
      )}
    </div>
  )
}
