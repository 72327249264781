import { Close } from '@mui/icons-material'
import { Button, Loader } from 'components'
import moment from 'moment'
import { useState } from 'react'
import './ModalExportHistory.scss'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { get, post } from '@osrdata/app_core/dist/requests'
import { useDispatch } from 'react-redux'
import { setSnackbar } from 'reducers/app'
import { SnackbarSeverity } from 'types'
import terms from 'assets/terms'

interface Props {
  technicalCenterId: string;
  handleClose: () => void;
}

export default function ModalExportHistory({ technicalCenterId, handleClose }: Props) {
  const dispatch = useDispatch()
  const [dateStart, setDateStart] = useState<moment.Moment>(moment())
  const [dateEnd, setDateEnd] = useState<moment.Moment>(moment())
  const [pending, setPending] = useState(false)

  const handleValidate = () => {
    post(`/r2d2/technicentres/${technicalCenterId}/missions/export/`, {
      date_debut: dateStart.format('YYYY-MM-DD'),
      date_fin: dateEnd.format('YYYY-MM-DD'),
    }).then(resp => {
      setPending(true)
      const interval = setInterval(async () => {
        const r = await get(`/r2d2/status/${resp.id}/`)
        if (r.status === 1) {
          clearInterval(interval)
          setPending(false)
          const file: {type: 'Buffer', data: number[]} = r.result_file
          // eslint-disable-next-line max-len
          const blob = new Blob([new Uint8Array(file.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `R2D2_export_${moment().format('YYYY_MM_DD_HH_mm_ss')}.xlsx`
          a.click()
          window.URL.revokeObjectURL(url)
          handleClose()
          dispatch(setSnackbar({ message: terms.Messages.success.exportHistory, severity: SnackbarSeverity.SUCCESS }))
        } else if (r.status === 2) {
          clearInterval(interval)
          setPending(false)
          dispatch(setSnackbar({ message: terms.Messages.errors.exportHistory, severity: SnackbarSeverity.ERROR }))
        }
      }, 1000)
    }).catch(() => {
      dispatch(setSnackbar({ message: 'Une erreur est survenue lors de l\'export', severity: SnackbarSeverity.ERROR }))
    })
  }

  return (
    <div className="modal-export-history">
      <div className="header">
        <h2>
          Export des missions archivées
        </h2>
        <button type="button" onClick={handleClose}>
          <Close />
        </button>
      </div>
      <div className="content">
        <span>Sélectionnez les dates et cliquez sur valider</span>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Date de début"
            value={dateStart}
            onChange={setDateStart}
            className="date-picker"
          />
          <DatePicker
            label="Date de fin"
            value={dateEnd}
            onChange={setDateEnd}
            className="date-picker"
          />
        </LocalizationProvider>
      </div>
      <div className="footer">
        {!pending && (
          <Button
            text="Valider"
            onClick={handleValidate}
          />
        )}
        {pending && (
          <div className="flex-center">
            <Loader message="Export des missions en cours" variant="large" />
          </div>
        )}
      </div>
    </div>
  )
}
