import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppRoles, MissionRDProgress, MissionStatus } from 'types'
import { useAppDispatch, useAppSelector, usePageRoles } from 'utils'
import { Button, Loader } from 'components'
import { getMissionRD } from 'reducers/rd/thunks'
import { getMissionRDProgress, routes } from 'services'
import { getDefects, getObservations, getTechnicalCenters } from 'reducers/params/thunks'
import BackButton from './BackButton'
import MissionInfos from './MissionInfos'
import MissionProgress from './MissionProgress'
import MissionActions from './MissionActions'
import MissionAdditionalData from './MissionAdditionalData'
import './index.scss'

export default function MissionDetail() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  usePageRoles([AppRoles.RD])
  const missionList = useParams<{statut: string}>()
  const missionId = useParams<{id: string}>()
  const { activeRole } = useAppSelector(state => state.app)
  const { missionRD, loadingMissionRD } = useAppSelector(state => state.rd)

  useEffect(() => {
    if (activeRole && activeRole.role !== AppRoles.admin) {
      const { technicenterId } = activeRole
      const idMission = missionId.id
      dispatch(getMissionRD({ technicenterId, idMission }))
      dispatch(getDefects())
      dispatch(getObservations())
      dispatch(getTechnicalCenters())
    }
  }, [activeRole])

  const missionProgress = getMissionRDProgress(missionRD)

  if (loadingMissionRD && !missionRD) {
    return (
      <div className="flex-center" style={{ height: '100%' }}>
        <Loader />
      </div>
    )
  }

  return (
    <div className="rd-mission-details">
      <BackButton
        onClick={() => {
          navigate(`${routes.rd}${routes.rdMissionsList}/${missionList.statut}`)
        }}
      />
      <div className={`content ${missionProgress === MissionRDProgress.testsStarted ? 'npd' : ''}`}>
        {missionProgress === MissionRDProgress.testsStarted && (
          <span className="npd-label">
            Vous êtes en mode conduite - Ne pas déranger
          </span>
        )}
        <MissionInfos
          technicalCenterId={activeRole?.role === AppRoles.RD ? activeRole.technicenterId : ''}
          mission={missionRD}
          displayNumber={missionProgress === MissionRDProgress.notStarted}
          displayTitle={missionProgress === MissionRDProgress.notStarted}
        />
        <MissionProgress mission={missionRD} />
      </div>
      {activeRole?.role === AppRoles.RD && (
        <MissionAdditionalData
          mission={missionRD}
          missionProgress={missionProgress}
          technicalCenterId={activeRole.technicenterId}
        />
      )}
      {(activeRole?.role === AppRoles.RD
      && (missionRD?.statut === MissionStatus.assigned || missionRD?.statut === MissionStatus.inProgress)) && (
        <MissionActions
          mission={missionRD}
          technicalCenterId={activeRole.technicenterId}
          progress={missionProgress}
          additionalMissions={missionRD.missions_supplementaires}
        />
      )}
      {missionRD?.statut === MissionStatus.validated && (
        <Button
          text="Retour au menu"
          onClick={() => navigate(routes.rd)}
          fullWidth
        />
      )}
    </div>
  )
}
