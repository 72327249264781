import { FormValues } from 'components/modals/addMissionTC/formHandlers'
import { MissionTC } from 'types'

// --- Remove empty values before submit ---
const cleanFormValues = (values: FormValues) => {
  const cleanedValues: Partial<MissionTC> = Object.keys(values).reduce((acc, key) => {
    if (values[key as keyof typeof values] !== ''
      && !(key === 'missions_supplementaires' && Array.isArray(values[key]) && values[key].length === 0)) {
      acc[key] = values[key]
    }
    return acc
  }, {})
  return cleanedValues
}
// ------
const cleanInProgressFormValues = (values: FormValues) => {
  // Remove rame, agent, heure_debut_theorique, heure_fin_theorique
  const newValues = cleanFormValues(values)
  delete newValues.rame
  delete newValues.agent
  delete newValues.heure_debut_theorique
  delete newValues.heure_fin_theorique
  return newValues
}
export {
  cleanFormValues,
  cleanInProgressFormValues,
}
