import { Autocomplete, TextField } from '@mui/material'
import { debounce } from 'lodash'
import { ReactElement, useCallback, useState } from 'react'
import { CerbereUser } from 'types'
import { get } from '@osrdata/app_core/dist/requests'
import Loader from 'components/loaderComponent/Loader'
import terms from 'assets/terms'
import HighlightMatch from './HighlightMatch'

interface UserSearchProps {
  placeholder: string
  label: string
  size: 'small' | 'medium'
  handleSelectUser: (user: CerbereUser) => void
  className?: string
  required?: boolean
  disabled?: boolean
}
function UserSearch({
  placeholder,
  label,
  size,
  handleSelectUser,
  required = false,
  disabled = false,
}: UserSearchProps): ReactElement {
  const [users, setUsers] = useState<CerbereUser[]>([])
  const [selectedUser, setSelectedUser] = useState<CerbereUser | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const debouncedSearch = useCallback(debounce((search: string) => {
    setLoading(true)
    get('/cerbere/users/', { search }).then(res => {
      setUsers(res.results.filter(u => u.enabled))
      setLoading(false)
    })
  }, 500), [])

  const formatUser = (user: CerbereUser) => `${user?.lastName?.toUpperCase()} ${user?.firstName}`

  const handleSearch = (newValue: string) => {
    setInputValue(newValue)
    if (newValue.length > 2) {
      debouncedSearch(newValue)
    } else {
      debouncedSearch.cancel()
      setUsers([])
    }
  }

  const handleChange = (_e: React.ChangeEvent, newValue: CerbereUser | null) => {
    setSelectedUser(newValue)
    debouncedSearch.cancel()
    handleSelectUser(newValue)
  }

  const displayUserName = (user: CerbereUser) => {
    if (user?.firstName && user?.lastName && user?.username) {
      return `${user.lastName?.toUpperCase()} ${user.firstName} (${user.username})`
    }
    if (user?.firstName && user?.lastName) {
      return `${user.lastName?.toUpperCase()} ${user.firstName}`
    } if (user?.username) {
      return user.username
    }
    return user.displayName
  }

  return (
    <div className="user-search">
      <Autocomplete
        disablePortal
        disabled={disabled}
        loading={loading}
        loadingText={`${terms.Common.loading}...`}
        options={[
          ...(selectedUser ? [selectedUser] : []),
          ...users.filter(u => u.id !== selectedUser?.id),
        ]}
        filterOptions={options => options}
        getOptionLabel={formatUser}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <HighlightMatch
              optionLabel={displayUserName(option)}
              inputValue={inputValue}
            />
          </li>
        )}
        value={selectedUser}
        noOptionsText={terms.Common.noResults}
        renderInput={params => (
          <TextField
            {...params}
            sx={{ '& .MuiFormLabel-asterisk': { color: '#DA4238' } }}
            placeholder={placeholder}
            fullWidth
            size={size}
            label={label}
            required={required}
            error={inputValue.length < 3 && inputValue.length !== 0}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {loading && <Loader variant="small" />}
                </>
              ),
            }}
          />
        )}
        onInputChange={(_, newInputValue) => handleSearch(newInputValue)}
        onChange={handleChange}
      />
    </div>
  )
}

export default UserSearch
