import { Add, Delete } from '@mui/icons-material'
import terms from 'assets/terms'
import { AddTwoFieldInputs, AsyncInput, Button, Loader, TooltipButton } from 'components'
import { useEffect, useState } from 'react'
import { getAdditionalMissions } from 'reducers/params/thunks'
import { useAppDispatch, useAppSelector } from 'utils'
import './AdditionalMissionsForm.scss'
import {
  handleDeleteAdditionalMission, handlePatchAdditionalMissionDuration,
  handlePatchAdditionalMissionName, handlePostAdditionalMission,
  handleReactivateAdditionalMission,
} from 'services'

const localTerms = terms.Pages.Params.additionalMissions
export default function AdditionalMissionsForm() {
  const dispatch = useAppDispatch()
  const { additionalMissions, additionalMissionsLoading } = useAppSelector(state => state.params)
  const [addingMission, setAddingMission] = useState(false)

  useEffect(() => {
    dispatch(getAdditionalMissions())
  }, [])

  if (additionalMissionsLoading) {
    return (
      <Loader message={localTerms.loading} standalone />
    )
  }

  return (
    <div className="additional-missions-form">
      {additionalMissions.map(mission => (
        <div className={`item ${mission.active ? '' : 'inactive'}`} key={mission.id}>
          <div className="input">
            <span>{localTerms.label}</span>
            <AsyncInput
              value={mission.libelle}
              handleChangeCallback={handlePatchAdditionalMissionName(mission.id)}
            />
          </div>
          <div className="input">
            <span>{localTerms.duration}</span>
            <AsyncInput
              value={mission.duree_theorique.toFixed()}
              handleChangeCallback={handlePatchAdditionalMissionDuration(mission.id)}
              type="number"
            />
          </div>
          {mission.active ? (
            <TooltipButton
              icon={<Delete />}
              tooltipText={localTerms.delete}
              onClick={handleDeleteAdditionalMission(mission.id)}
            />
          ) : (
            <TooltipButton
              icon={<Add />}
              tooltipText={localTerms.reactivate}
              onClick={handleReactivateAdditionalMission(mission.id)}
            />
          )}

        </div>
      ))}
      {addingMission ? (
        <AddTwoFieldInputs
          fieldsTitle={[localTerms.label, localTerms.duration]}
          fieldsType={['text', 'number']}
          addTooltip={localTerms.add}
          cancelTooltip={localTerms.cancel}
          handleAdd={handlePostAdditionalMission(() => setAddingMission(false))}
          handleDelete={() => () => setAddingMission(false)}
        />
      ) : (
        <Button
          className="add-additional-mission-button"
          onClick={() => setAddingMission(true)}
          text={localTerms.add}
          startIcon={<Add />}
        />
      )}
    </div>
  )
}
