import {
  Box, ListItemText, MenuItem, Select, SelectChangeEvent,
} from '@mui/material'
import {
  AsyncInput, Checkbox, CheckboxFormField, Loader, SelectFormField, TextFormField, TimeFormField,
} from 'components'
import terms from 'assets/terms'
import { useAppSelector, useCerbereUsers } from 'utils'
import { MissionOption } from 'types'
import { FormValues } from '../formHandlers'

const getMissionColor = (mission: MissionOption) => {
  if (mission.gmao) {
    if (mission.gmao.type_flux === 'DI' && mission.gmao.is_matching_modele) return '#bd74f9'
    if (mission.gmao.type_flux === 'OTMT' && mission.gmao.is_matching_modele) return '#1aaf0f'
    if (mission.gmao.type_flux === 'OTMT' && !mission.gmao.is_matching_modele) return '#f9841a'
  }
  return '#000000'
}

interface Props {
  formValues: FormValues
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>) => void
  handleSelectMission: (id: string) => void
  handleCheckboxChange: (name: string) => () => void
  handleTimeChange: (name: string, value: moment.Moment) => void
  handleMultiSelectChange: (e: SelectChangeEvent<string[]>) => void
  handleChangeRame: (value: string) => Promise<void>
  technicalCenterId: string
  requiredFields: string[]
  isInProgress: boolean
  missionOptions: MissionOption[]
  canEditRequired: boolean
  pendingGMAO?: boolean
}

const localTerms = terms.Modals.AddMissionTC
export default function MissionTCFields({
  formValues, handleInputChange, handleSelectMission, handleCheckboxChange, handleTimeChange, handleMultiSelectChange,
  handleChangeRame, technicalCenterId, requiredFields, isInProgress, missionOptions, canEditRequired,
  pendingGMAO = false,
}: Props) {
  const { agents } = useAppSelector(state => state.missionTable)
  const { technicalCenters, additionalMissions } = useAppSelector(state => state.params)
  const { getUserName } = useCerbereUsers(agents.map(agent => agent.cerbere_id))

  const selectedChantierDepart = technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.find(
    chantier => chantier.id === formValues.chantier_depart,
  )

  const selectedChantierArrivee = technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.find(
    chantier => chantier.id === formValues.chantier_arrivee,
  )

  const noModeleSelected = formValues.modele === '' && formValues.gmao === ''

  if (pendingGMAO) {
    return (
      <div className="loader">
        <Loader message="Chargement de la mission" />
      </div>
    )
  }

  return (
    <div className="fields">
      {/* Rame */}
      <Box display="flex" flexDirection="column" gap="4px" marginBottom="-22px">
        <Box display="flex" flexDirection="column" gap="4px">
          <span style={{ fontSize: '12px', fontWeight: 400, color: '#525967' }}>
            {localTerms.rame}
            <span style={{ color: '#DA4238' }}>*</span>
          </span>
          <AsyncInput
            value={formValues.rame}
            handleChangeCallback={handleChangeRame}
          />
        </Box>
        <CheckboxFormField
          value={formValues.um}
          onChange={handleCheckboxChange('um')}
          name={localTerms.um}
        />
      </Box>
      {/* Matériel attelé */}
      <TextFormField
        value={formValues.um_details}
        onChange={handleInputChange}
        label={localTerms.umDetails}
        disabled={!formValues.um}
        name="um_details"
      />
      {/* Mission */}
      <div className="select-form-field">
        <span>
          {localTerms.mission}
        </span>
        <Select
          value={formValues.modele || formValues.gmao || ''}
          onChange={(event: SelectChangeEvent) => handleSelectMission(event.target.value)}
          renderValue={value => missionOptions.find(option => option.id === value)?.libelle || localTerms.selectMission}
          sx={{
            height: '48px',
            padding: 0,
            border: '1px solid #D7E1F3',
            fontSize: '14px',

            '&:focus, &:hover': {
              border: '1px solid #256EFF',
            },

            '&.Mui-disabled': {
              background: '#F1F1F1',
            },

            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        >
          <MenuItem value="" disabled>
            {localTerms.selectMission}
          </MenuItem>
          {missionOptions.map(option => (
            <MenuItem key={option.id} value={option.id} sx={{ color: getMissionColor(option), fontSize: '14px' }}>
              {option.libelle}
            </MenuItem>
          ))}
        </Select>
      </div>
      {/* Non visible pour le RD */}
      <CheckboxFormField
        value={formValues.prioritaire}
        onChange={handleCheckboxChange('prioritaire')}
        label={localTerms.rdHidden}
        name={localTerms.urgent}
      />
      {/* Chantier départ */}
      <SelectFormField
        value={formValues.chantier_depart}
        onChange={handleInputChange}
        label={localTerms.chantier_depart}
        name="chantier_depart"
        options={technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.map(
          chantier => ({ id: chantier.id, libelle: chantier.libelle }),
        )}
        required={requiredFields.includes('chantier_depart')}
        disabled={!requiredFields.includes('chantier_depart') && !canEditRequired}
      />
      {/* Chantier arrivée */}
      <SelectFormField
        value={formValues.chantier_arrivee}
        onChange={handleInputChange}
        label={localTerms.chantier_arrivee}
        name="chantier_arrivee"
        options={technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.map(
          chantier => ({ id: chantier.id, libelle: chantier.libelle }),
        )}
        required={requiredFields.includes('chantier_arrivee')}
        disabled={!requiredFields.includes('chantier_arrivee') && !canEditRequired}
      />
      {/* Voie de départ */}
      <SelectFormField
        value={formValues.voie_depart}
        onChange={handleInputChange}
        label={localTerms.voie_depart}
        name="voie_depart"
        options={selectedChantierDepart
          ? selectedChantierDepart.voies.map(voie => ({ id: voie.id, libelle: voie.libelle }))
          : technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.flatMap(chantier => chantier.voies.map(
            voie => ({ id: voie.id, libelle: voie.libelle }),
          ))}
        required={requiredFields.includes('voie_depart')}
        disabled={!requiredFields.includes('voie_depart') && !canEditRequired}
      />
      {/* Voie d‘arrivée */}
      <SelectFormField
        value={formValues.voie_arrivee}
        onChange={handleInputChange}
        label={localTerms.voie_arrivee}
        name="voie_arrivee"
        options={selectedChantierArrivee
          ? selectedChantierArrivee.voies.map(voie => ({ id: voie.id, libelle: voie.libelle }))
          : technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.flatMap(chantier => chantier.voies.map(
            voie => ({ id: voie.id, libelle: voie.libelle }),
          ))}
        required={requiredFields.includes('voie_arrivee')}
        disabled={!requiredFields.includes('voie_arrivee') && !canEditRequired}
      />
      {/* Heure début théorique */}
      <TimeFormField
        value={formValues.heure_debut_theorique}
        onChange={value => handleTimeChange('heure_debut_theorique', value)}
        label={localTerms.heure_debut_theorique}
        required
        disabled={noModeleSelected || isInProgress}
      />
      {/* Heure fin théorique */}
      <TimeFormField
        value={formValues.heure_fin_theorique}
        onChange={value => handleTimeChange('heure_fin_theorique', value)}
        label={localTerms.heure_fin_theorique}
        required
        disabled={noModeleSelected || isInProgress}
      />
      {/* Agent */}
      <SelectFormField
        value={formValues.agent}
        onChange={handleInputChange}
        label={localTerms.agent}
        name="agent"
        options={agents.filter(agent => agent.active).map(agent => ({ id: agent.cerbere_id,
          libelle: getUserName(agent.cerbere_id) }))}
        required
        disabled={noModeleSelected || isInProgress}
      />
      {/* Sens de départ */}
      <SelectFormField
        value={formValues.sens_depart}
        onChange={handleInputChange}
        label={localTerms.sens_depart}
        name="sens_depart"
        options={technicalCenters.find(tc => tc.id === technicalCenterId)?.sens_depart.map(
          depart => ({ id: depart.id, libelle: depart.libelle }),
        )}
        required={requiredFields.includes('sens_depart')}
        disabled={!requiredFields.includes('sens_depart') && !canEditRequired}
      />
      {/* Via */}
      <TextFormField
        value={formValues.via}
        onChange={handleInputChange}
        label={localTerms.via}
        required={requiredFields.includes('via')}
        name="via"
        disabled={!requiredFields.includes('via') && !canEditRequired}
      />
      {/* Missions supplémentaires */}
      <div className="select-form-field">
        <span>
          {localTerms.missions_supplementaires}
        </span>
        <Select
          multiple
          value={formValues.missions_supplementaires}
          onChange={handleMultiSelectChange}
          renderValue={values => values.map(id => additionalMissions
            .find(additional => additional.id === id)?.libelle).join(', ')}
          sx={{
            height: '48px',
            padding: 0,
            border: '1px solid #D7E1F3',

            '&:focus, &:hover': {
              border: '1px solid #256EFF',
            },

            '&.Mui-disabled': {
              background: '#F1F1F1',
            },

            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
          disabled={noModeleSelected}
        >
          {additionalMissions.map(additional => (
            <MenuItem key={additional.id} value={additional.id}>
              <Checkbox readonly checked={formValues.missions_supplementaires.includes(additional.id)} />
              <ListItemText primary={additional.libelle} sx={{ marginLeft: 1 }} />
            </MenuItem>
          ))}
        </Select>
      </div>

      {/* Observation COM */}
      <TextFormField
        value={formValues.observation_com}
        onChange={handleInputChange}
        label={localTerms.observation_com}
        name="observation_com"
        disabled={noModeleSelected}
      />
      {/* Pancartage */}
      <TextFormField
        value={formValues.pancartage}
        onChange={handleInputChange}
        label={localTerms.pancartage}
        name="pancartage"
        disabled={noModeleSelected}
      />
    </div>
  )
}
