import { useState } from 'react'
import terms from 'assets/terms'
import { Button, ButtonStyle } from 'components'
import './ModalAddTeam.scss'

interface Props {
  title: string;
  label: string;
  handleClose: () => void;
  handleValidate: (teamName: string) => () => void;
}

export default function ModalAddTeam({
  title, label, handleClose, handleValidate,
}: Props) {
  const [inputValue, setInputValue] = useState<string>('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  return (
    <div className="modal-add-team">
      <h3>{title}</h3>
      <div className="input">
        <span>{label}</span>
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          maxLength={100}
        />
      </div>
      <div className="actions">
        <Button
          text={terms.Common.cancel}
          onClick={handleClose}
          style={ButtonStyle.light}
        />
        <Button
          text={terms.Common.save}
          onClick={handleValidate(inputValue)}
          disabled={!inputValue}
        />
      </div>
    </div>
  )
}
