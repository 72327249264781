// import svg as React Component
import { ErrorOutline } from '@mui/icons-material'
import terms from 'assets/terms'
import { Button, ButtonStyle } from 'components'
import './ModalError.scss'

interface Props {
  title: string;
  handleClose: () => void;
}

export default function ModalError(
  { title, handleClose }: Props,
) {
  return (
    <div className="modal-error">
      <div className="error-icon">
        <ErrorOutline />
      </div>
      <h3>{title}</h3>
      <div className="actions">
        <Button text={terms.Common.close} onClick={handleClose} style={ButtonStyle.light} />
      </div>
    </div>
  )
}
