import { get } from '@osrdata/app_core/dist/requests'
import { notificationsSignal } from 'components'
import { notificationsMessagesSignal } from 'components/notifications/NotificationWrapper'
import { getMissionsTC, getTCFilters, updateMission } from 'reducers/missionTable/thunks'
import { store } from 'reducers/store'
import { NotificationMessage, MissionNotification, MessageNotification } from 'types'
import { v4 as uuidv4 } from 'uuid'

// eslint-disable-next-line max-len
export function isMissionNotification(value: Omit<NotificationMessage, 'id'>): value is Omit<MissionNotification, 'id'> {
  const predicate = value as MissionNotification
  return predicate.numero !== undefined && predicate.mission !== undefined && predicate.origine !== undefined
  && predicate.timestamp !== undefined && predicate.destinataire !== undefined
}

export const updateNotificationMessages = (centerId: string) => get<MessageNotification[]>(
  `/r2d2/technicentres/${centerId}/connect/messages/`,
).then(resp => {
  notificationsMessagesSignal.value = resp
})

export const handleNotificationMessage = (
  message: Omit<NotificationMessage, 'id'>,
  centerId: string,
  missionsOrdering: string,
) => {
  try {
    if (isMissionNotification(message)) {
      const messageId: string = uuidv4()
      const newMessage = { id: messageId, ...message }
      notificationsSignal.value = [...notificationsSignal.value, newMessage]
      store.dispatch(updateMission({ centerId, missionId: message.mission }))
      if (message.type === 'CREATION') {
        store.dispatch(getMissionsTC({ technicenterId: centerId, ordering: missionsOrdering }))
      } else if (message.type === 'ARCHIVAGE') {
        store.dispatch(getTCFilters(centerId))
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while handling notification message', e)
  }
}
