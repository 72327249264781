import { Button, ButtonStyle } from 'components'
import { useState } from 'react'

type Option = {
  id: string
  libelle: string
}

interface Props {
  count: number
  options: Option[]
  selectedOptions: Option[]
  handleValidate: (options: Option, done: boolean) => void
  handleClose: () => void
}

export default function FormAdditionalMissions({
  count, options, selectedOptions, handleValidate, handleClose,
}: Props) {
  const [newOptions, setNewOptions] = useState<Option[]>(selectedOptions)

  return (
    <div className="rd-form-additionnal-missions">
      <strong>Missions supplémentaires</strong>
      {count > 0 ? (
        <span className="count">
          {count}
          {' '}
          à valider
        </span>
      ) : (
        <span className="count valid">Toutes les missions supplémentaires sont validées</span>
      )}
      <div className="options">
        {options.map(option => (
          <div className="option" key={option.id}>
            <strong>{option.libelle}</strong>
            <Button
              text={newOptions.map(o => o.id).includes(option.id) ? 'Annuler' : 'Valider cette mission'}
              onClick={() => {
                if (newOptions.map(o => o.id).includes(option.id)) {
                  setNewOptions(newOptions.filter(o => o.id !== option.id))
                  handleValidate(option, false)
                } else {
                  setNewOptions([...newOptions, option])
                  handleValidate(option, true)
                }
              }}
              style={newOptions.map(o => o.id).includes(option.id) ? ButtonStyle.light : ButtonStyle.success}
            />
          </div>

        ))}
      </div>
      <div className="actions">
        <Button text="Retour" onClick={handleClose} fullWidth />
      </div>
    </div>
  )
}
