import { Button, notificationsSignal } from 'components'
import { signal } from '@preact/signals-react'
import 'moment/locale/fr'
import {
  Stack, Checkbox, Drawer, Box, FormControlLabel,
  Badge,
  Switch,
} from '@mui/material'
import * as moment from 'moment'
import { Close } from '@mui/icons-material'
import { notificationsMessagesSignal, notificationsTypesSignal } from 'components/notifications/NotificationWrapper'
import { MessageNotification, MissionNotification, MissionNotificationType } from 'types'
import { deleteRequest } from '@osrdata/app_core/dist/requests'
import { useState } from 'react'

moment.locale('fr')

export const notificationsDrawerSignal = signal<boolean>(false)

// 'MODIFICATION' | 'REFUS' | 'SUPPRESSION' | 'CREATION' | 'AFFECTATION' | 'VALIDATION' | 'ARCHIVAGE'
const checkboxes: {
  label: string;
  color: string;
  types: MissionNotificationType[];
}[] = [
  {
    label: 'Création',
    color: '#3B38DA',
    types: ['CREATION'],
  },
  {
    label: 'Modification',
    color: '#FAB142',
    types: ['MODIFICATION'],
  },
  {
    label: 'Refus / Suppression',
    color: '#DA4238',
    types: ['REFUS', 'SUPPRESSION'],
  },
  {
    label: 'Validation',
    color: '#43CF7B',
    types: ['VALIDATION'],
  },
  {
    label: 'Archivage',
    color: '#3A4457',
    types: ['ARCHIVAGE'],
  },
]

const message: { [key in MissionNotification['type']]: string } = {
  AFFECTATION: 'Affectée',
  MODIFICATION: 'Complétée',
  REFUS: 'Refusée',
  CREATION: 'Ajoutée',
  SUPPRESSION: 'Supprimée',
  VALIDATION: 'Validée',
  ARCHIVAGE: 'Archivée',
}

export default function NotificationsDrawer({ technicalCenterId }: {technicalCenterId: string}) {
  const [onlyUnread, setOnlyUnread] = useState(false)
  const toggleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyUnread(event.target.checked)
  }

  const toggleDrawer = (newOpen: boolean) => () => {
    notificationsDrawerSignal.value = newOpen
  }

  const handleClickClose = (not: MessageNotification) => () => {
    if (not.vu) return
    deleteRequest(`/r2d2/technicentres/${technicalCenterId}/connect/messages/${not.id}/`).then(() => {
      notificationsMessagesSignal.value = notificationsMessagesSignal.value.map(n => {
        if (n.id === not.id) {
          return { ...n, vu: true }
        }
        return n
      })
      notificationsSignal.value = notificationsSignal.value.filter(n => n.id !== not.id)
    })
  }

  const handleClickReadAll = () => {
    notificationsMessagesSignal.value.filter(n => !n.vu).forEach(not => {
      handleClickClose(not)()
    })
  }

  const DrawerList = (
    <Box role="presentation" p={3} borderRadius={5}>
      <Box mb={3} fontWeight={800} fontSize={20} borderRadius={5}>
        <span>NOTIFICATIONS</span>
      </Box>
      <Stack direction="row">
        {checkboxes.map(cb => (
          <FormControlLabel
            sx={{ color: cb.color }}
            key={cb.label}
            control={(
              <Checkbox
                size="small"
                sx={{
                  color: '#D7E1F3',
                  '&.Mui-checked': {
                    color: cb.color,
                  },
                }}
                checked={notificationsTypesSignal.value.some(type => cb.types.includes(type))}
                onChange={() => {
                  if (notificationsTypesSignal.value.some(type => cb.types.includes(type))) {
                    notificationsTypesSignal.value = notificationsTypesSignal.value
                      .filter(type => !cb.types.includes(type))
                  } else {
                    notificationsTypesSignal.value = [...notificationsTypesSignal.value, ...cb.types]
                  }
                }}
              />
            )}
            label={(
              <Box fontWeight={800} color={cb.color}>
                <span>{cb.label}</span>
              </Box>
)}
          />
        ))}
      </Stack>
      <Box mt={1} mb={3} display="flex" justifyContent="space-between">
        <Button
          text="Tout marquer comme lu"
          onClick={handleClickReadAll}
        />
        <FormControlLabel
          control={<Switch checked={onlyUnread} onChange={toggleSwitch} />}
          label="Uniquement les non lues"
          labelPlacement="start"
        />
      </Box>

      {notificationsMessagesSignal.value.filter(not => (onlyUnread ? !not.vu : true)).map(not => (
        <Box
          key={not.id}
          display="flex"
          justifyContent="space-between"
          mt={2}
          borderTop="1px solid rgba(0, 0, 0, 0.12)"
          pt={2}
        >
          <Badge invisible={not.vu} color="primary" variant="dot" sx={{ '& .MuiBadge-badge': { right: '-10px' } }}>
            <Box display="flex" flexDirection="column">
              <Box fontSize={20}>
                {`Mission ${not.message.numero} : ${message[not.message.type]} par le ${not.message.origine}`}

              </Box>
              <Box fontSize={20} fontWeight={800}>
                {moment(not.message.timestamp).format('ddd DD MMM YYYY')}
                {' '}
                à
                {' '}
                {moment(not.message.timestamp).format('HH:mm')}
              </Box>
            </Box>
          </Badge>
          {!not.vu && (
            <button
              type="button"
              style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
              onClick={handleClickClose(not)}
            >
              <Close />
            </button>
          )}
        </Box>
      ))}
    </Box>
  )

  return (
    <div className="notif-drawer">
      <Drawer
        open={notificationsDrawerSignal.value}
        onClose={toggleDrawer(false)}
        anchor="right"
        PaperProps={{
          sx: {
            marginTop: 18,
            borderTopLeftRadius: 8,
            height: 'calc(100% - 144px)',
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </div>
  )
}
