import { Add, Delete } from '@mui/icons-material'
import terms from 'assets/terms'
import { AsyncInput, Button, Loader, AddFieldInput, TooltipButton } from 'components'
import { useEffect, useState } from 'react'
import { getObservations } from 'reducers/params/thunks'
import { useAppDispatch, useAppSelector } from 'utils'
import {
  handleChangeObservationName, handleDeleteObservation,
  handlePostNewObservation, handleReactivateObservation,
} from 'services'
import './ObservationsForm.scss'

const localTerms = terms.Pages.Params.observations
export default function ObservationsForm() {
  const dispatch = useAppDispatch()
  const { observations, observationsLoading } = useAppSelector(state => state.params)
  const [addingObservation, setAddingObservation] = useState(false)

  useEffect(() => {
    dispatch(getObservations())
  }, [])

  if (observationsLoading) {
    return (
      <Loader message={localTerms.loading} standalone />
    )
  }

  return (
    <div className="observations-form">
      {observations.map(observation => (
        <div className={`item ${observation.active ? '' : 'inactive'}`} key={observation.id}>
          <div className="input">
            <span>{localTerms.label}</span>
            <AsyncInput
              value={observation.libelle}
              handleChangeCallback={handleChangeObservationName(observation.id)}
            />
          </div>
          {observation.active ? (
            <TooltipButton
              icon={<Delete />}
              tooltipText={localTerms.delete}
              onClick={handleDeleteObservation(observation.id)}
            />
          ) : (
            <TooltipButton
              icon={<Add />}
              tooltipText={localTerms.reactivate}
              onClick={handleReactivateObservation(observation.id)}
            />
          )}

        </div>
      ))}
      {addingObservation ? (
        <AddFieldInput
          title={localTerms.label}
          addTooltip={localTerms.add}
          cancelTooltip={localTerms.cancel}
          handleAdd={handlePostNewObservation(() => setAddingObservation(false))}
          handleDelete={() => () => setAddingObservation(false)}
        />
      ) : (
        <Button
          className="add-observation-button"
          onClick={() => setAddingObservation(true)}
          text={localTerms.add}
          startIcon={<Add />}
        />
      )}
    </div>
  )
}
