import { GMAOModele } from './gmao'
import {
  ParamsAdditionalMission, ParamsConstruction,
  ParamsDefects, ParamsDirection, ParamsMission,
  ParamsObservations, ParamsTrack,
} from './params'

export type MissionTeam = {
  id: string;
  libelle: string;
  active: boolean;
  agents: string[];
}

export type MissionAgent = {
  cerbere_id: string;
  equipe: string;
  active: boolean;
}

export enum MissionStatus {
  preAssigned = 'PREAFFECTEE',
  assigned = 'AFFECTEE',
  inProgress = 'MISSION_EN_COURS',
  validated = 'VALIDEE',
  canceled = 'REFUSEE',
  deleted = 'SUPPRIMEE',
}
export const MISSION_EDIT_STATUS: MissionStatus[] = [
  MissionStatus.assigned, MissionStatus.preAssigned, MissionStatus.inProgress,
]
export const MISSION_DELETE_STATUS: MissionStatus[] = [
  MissionStatus.assigned, MissionStatus.preAssigned, MissionStatus.inProgress, MissionStatus.canceled,
]
export const MISSION_ARCHIVE_STATUS: MissionStatus[] = [
  MissionStatus.validated, MissionStatus.deleted, MissionStatus.canceled,
]

export enum MissionBatchAction {
  assign = 'AFFECTEE',
  delete = 'SUPPRIMEE',
  archive = 'ARCHIVEE',
  export = 'EXPORT EXCEL',
}

export type TCFilter = {
  agents: { cerbere_id: string}[]
  agents_selected: string[]
  mission_modeles: {
    id: string,
    libelle: string,
  }[]
  mission_modeles_selected: string[]
  statuts: {
    libelle: MissionStatus,
  }[]
  statuts_selected: MissionStatus[]
  gmao_modeles: string
  gmao_modele_else_selected: boolean
}

export type OrientationMotrice = {
  principale?: string;
  secondaire?: string;
}

export type MissionTC = {
    id: string,
    numero: number,
    com: string,
    agent?: string,
    equipe?: string,
    heure_debut_theorique: string,
    heure_fin_theorique: string,
    observation_com?: string,
    observation_com_time?: string,
    commentaire_defauts_rd?: string,
    commentaire_observation_rd?: string,
    pancartage?: string,
    rame: string,
    um: boolean,
    um_details: string,
    statut: MissionStatus,
    via?: string,
    from_voyageurs: boolean,
    prise_en_compte?: string,
    presence_sur_engin?: string,
    debut_npd?: string,
    fin_npd?: string,
    archivee: boolean,
    prioritaire: boolean,
    modele?: ParamsMission,
    gmao?: GMAOModele,
    chantier_depart: ParamsConstruction | null,
    chantier_arrivee: ParamsConstruction | null,
    voie_depart: ParamsTrack | null,
    voie_arrivee: ParamsTrack | null,
    sens_depart: ParamsDirection | null,
    defauts_rd: ParamsDefects[],
    observations_rd: ParamsObservations[],
    orientation_motrice?: OrientationMotrice,
    missions_supplementaires: {
      id: string
      done: boolean | null
      done_time: string | null
      modele: ParamsAdditionalMission
    }[]
}

export type PatchFiltersParams = {
  technicenterId: string;
  filters: {
    agents_selected: string[];
    mission_modeles_selected: string[];
    statuts_selected: string[];
    gmao_modele_else_selected: boolean;
  };
}

export type MissionTableState = {
  teams: MissionTeam[];
  teamsLoading: boolean;
  agents: MissionAgent[];
  agentsLoading: boolean;
  activeTeams: string[];
  activeAgentsCount: number;
  missionsTC: MissionTC[];
  missionsPreAssigned: MissionTC[];
  missionsTCLoading: boolean;
  filters: TCFilter;
}
