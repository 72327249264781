import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { initTranslation } from '@osrdata/app_core/dist/translation'
import { SentryConfigured } from '@osrdata/app_core'
import { store, persistor } from './reducers/store'
import 'assets/css/constants.scss'
import App from './App'

SentryConfigured()
initTranslation()

let manifestHref = '/manifest.json'
if (window.location.hostname === 'localhost' || window.location.hostname.includes('dev')) {
  manifestHref = '/manifest-dev.json'
} else if (window.location.hostname.includes('staging')) {
  manifestHref = '/manifest-staging.json'
}
const link = document.getElementById('manifest-link') as HTMLLinkElement
link.href = manifestHref

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
)
