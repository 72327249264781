import terms from 'assets/terms'
import { Add, Delete } from '@mui/icons-material'
import { AsyncInput, AddFieldInput, TooltipButton } from 'components'
import { ParamsConstruction } from 'types'
import { useState } from 'react'
import {
  handleDeleteConstruction, handleDeleteTrack, handlePatchConstructionName, handlePatchTrackName,
  handlePostNewTrack,
  handleReactivateConstruction,
  handleReactivateTrack,
} from 'services'
import './ConstructionForm.scss'

interface Props {
  construction: ParamsConstruction;
  constructionIndex: number;
  centerId: string;
}

const localTerms = terms.Pages.Params.technicalCenters
export default function ConstructionForm({ construction, constructionIndex, centerId }: Props) {
  const [addingTrack, setAddingTrack] = useState(false)

  const handlePost = handlePostNewTrack(centerId, construction.id, () => setAddingTrack(false))

  return (
    <div className="construction-item">
      <div className={`construction-header ${construction.active ? '' : 'inactive'}`}>
        <div className="construction-header-input">
          <div className="construction-header-text">
            <span className="title">
              {`${localTerms.constructionTitle}${constructionIndex + 1}`}
            </span>
            {!addingTrack && (
            <button type="button" className="add-track" onClick={() => setAddingTrack(true)}>
              <Add />
              <span>{localTerms.addTrack}</span>
            </button>
            )}
          </div>
          <AsyncInput
            value={construction.libelle}
            handleChangeCallback={handlePatchConstructionName(centerId, construction.id)}
          />
        </div>
        {construction.active ? (
          <TooltipButton
            icon={<Delete />}
            tooltipText={localTerms.deleteConstruction}
            onClick={handleDeleteConstruction(centerId, construction.id)}
          />
        ) : (
          <TooltipButton
            icon={<Add />}
            tooltipText={localTerms.reactivateConstruction}
            onClick={handleReactivateConstruction(centerId, construction.id)}
          />
        )}
      </div>
      {construction.voies.map((voie, voieIndex) => (
        <div className={`voie-item ${voie.active ? '' : 'inactive'}`} key={voie.id}>
          <div className="voie-header">
            <span>{`${localTerms.trackTitle}${voieIndex + 1}`}</span>
            <AsyncInput
              value={voie.libelle}
              handleChangeCallback={handlePatchTrackName(centerId, construction.id, voie.id)}
            />
          </div>
          {voie.active ? (
            <TooltipButton
              icon={<Delete />}
              tooltipText={localTerms.deleteTrack}
              onClick={handleDeleteTrack(centerId, construction.id, voie.id)}
            />
          ) : (
            <TooltipButton
              icon={<Add />}
              tooltipText={localTerms.reactivateTrack}
              onClick={handleReactivateTrack(centerId, construction.id, voie.id)}
            />
          )}
        </div>
      ))}
      {addingTrack && (
        <div className="voie-item">
          <AddFieldInput
            title={localTerms.newTrack}
            addTooltip={localTerms.addTrack}
            cancelTooltip={localTerms.cancelTrack}
            handleAdd={handlePost}
            handleDelete={() => () => setAddingTrack(false)}
          />
        </div>
      )}
    </div>
  )
}
