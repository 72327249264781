/* eslint-disable react/jsx-no-useless-fragment */
import { Check, Edit } from '@mui/icons-material'
import { Box, Chip, Stack } from '@mui/material'
import { DayGapBadge, TooltipButton } from 'components'
import moment from 'moment'
import { useState } from 'react'
import { getMissionRD } from 'reducers/rd/thunks'
import { handlePatchMissionRD } from 'services'
import { MissionRD, MissionStatus } from 'types'
import { useAppDispatch } from 'utils'

interface Props {
  technicalCenterId: string
  mission: MissionRD
  displayNumber: boolean
  displayTitle: boolean
}

const chipColor = (statut: string) => {
  switch (statut) {
    case MissionStatus.validated:
      return 'success'
    case MissionStatus.deleted:
    case MissionStatus.canceled:
      return 'error'
    default:
      return 'primary'
  }
}

export default function MissionInfos({
  technicalCenterId, mission, displayNumber, displayTitle,
}: Props) {
  const dispatch = useAppDispatch()
  const [editVia, setEditVia] = useState(false)
  const [inputValue, setInputValue] = useState(mission?.via)

  const handleEditVia = handlePatchMissionRD(
    technicalCenterId,
    mission?.id,
    { via: inputValue },
    () => {
      setEditVia(false)
      dispatch(getMissionRD({ technicenterId: technicalCenterId, idMission: mission?.id }))
    },
  )
  return (
    <Stack direction="column" spacing={6} alignItems="flex-start" bgcolor="white" p="8px" borderRadius="8px">
      <Stack spacing={2} width="100%">
        {displayNumber && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <Chip
              sx={{ height: '50px', width: '50px', fontSize: 18 }}
              label={mission?.numero}
              style={{ borderRadius: 4 }}
              color={chipColor(mission?.statut)}
            />
            {mission?.statut === MissionStatus.validated && (
              <Chip
                label="Mission validée"
                style={{ borderRadius: 4 }}
                color="success"
              />
            )}
            {(mission?.statut === MissionStatus.deleted || mission?.statut === MissionStatus.canceled) && (
              <Chip
                label={mission?.statut === MissionStatus.deleted ? 'Mission supprimée' : 'Mission refusée'}
                style={{ borderRadius: 4 }}
                color="error"
              />
            )}
          </Box>
        )}

        {displayTitle && (
          <>
            <Stack color="#256EFF" fontSize={24}>
              <Box>
                {mission?.modele?.libelle || mission?.gmao?.libelle}
              </Box>
              <Box>
                {mission?.rame}
                {' '}
                {mission?.um ? `(${mission.um_details} UM)` : ''}
              </Box>
              <Box>
                {mission?.voie_depart?.libelle}
                {mission?.voie_arrivee ? ' > ' : ''}
                {mission?.voie_arrivee?.libelle}
              </Box>
              <Box>
                {mission?.sens_depart?.libelle}
              </Box>
            </Stack>
            <Box>
              {mission?.chantier_depart?.libelle}
              {mission?.voie_arrivee ? ' > ' : ''}
              {mission?.chantier_arrivee?.libelle}
            </Box>
          </>
        )}

        {mission?.statut === MissionStatus.canceled && (
          <Box>
            <strong>Raison de refus :</strong>
            {' '}
            {mission?.commentaire_observation_rd || 'Aucun'}
          </Box>
        )}

        <Stack>
          <Box>
            Sens de départ :
            {' '}
            <strong>{mission?.sens_depart ? mission.sens_depart.libelle : 'Non renseigné'}</strong>
          </Box>
          <Box display="flex" gap="4px" alignItems="center">
            <span>Via :</span>
            {editVia ? (
              <input type="text" value={inputValue} onChange={e => setInputValue(e.target.value)} maxLength={100} />
            ) : (
              <strong>{mission?.via ? mission.via : 'Non renseigné'}</strong>
            )}
            {mission?.statut === MissionStatus.inProgress && (
              <>
                {editVia ? (<TooltipButton tooltipText="Valider" icon={<Check />} onClick={handleEditVia} />)
                  : (<TooltipButton tooltipText="Modifier" icon={<Edit />} onClick={() => setEditVia(true)} />)}
              </>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            Début de mission théorique :
            <DayGapBadge time={mission?.heure_debut_theorique} />
            <strong>{moment(mission?.heure_debut_theorique).format('HH:mm')}</strong>
          </Box>
        </Stack>
        <Stack>
          <Box>
            <strong>Missions supplémentaires</strong>
          </Box>
          <Box>
            {mission?.missions_supplementaires.length !== 0
              ? mission?.missions_supplementaires.map((ms, index) => (
                <span key={ms.id}>
                  {ms.modele.libelle}
                  {index < mission.missions_supplementaires.length - 1 && ', '}
                </span>
              )) : 'Aucune'}
          </Box>

        </Stack>
        <Stack>
          <Box>
            <strong>Observations COM</strong>
          </Box>
          <Box>
            {mission?.observation_com ? mission.observation_com : 'Aucune'}
          </Box>
        </Stack>
        <Stack>
          <Box>
            <strong>Pancartage</strong>
          </Box>
          <Box>
            {mission?.pancartage ? mission.pancartage : 'Aucun'}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
